<template>
	<v-container>
		<div class="container-newspage">
			<v-alert border="left" type="info"> 
                No news were found
            </v-alert>
		</div>
	</v-container>
</template>

<script>
export default {
	name: "NewsPage",
};
</script>

<style scoped>
.container-newspage {
	max-width: 1000px;
	margin: 45px auto;
}
</style>